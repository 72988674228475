/* You can add global styles to this file, and also import other style files */

footer {
    color: black;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.menu {
    border-color: black;
    background-color: white;
    border-style: solid;
  }
